import React from "react";
import {graphql} from "gatsby";
import Img from "gatsby-image";
import Layout from "../templates/layout";

let InsuranceType = (props) =>{
    let list = props.list.map((el) =>{  
        return(
            <li key={el}className="text-blue-500 py-1 text-lg">{el}</li>
        )
    })
    return(
        <div  className="py-5 max-w-xs mx-auto">
            <h1 className="font-bold text-2xl">{props.title}</h1>
            <ul>
                {list}
            </ul>
        </div>
    )
}

export default (props) => {
    const data = props.data.allContentfulServicesPage.nodes[0];
    const banner = data.banner;
    const insurances = data.insuranceTypes;
    const logo = data.logo;
    return (
        <Layout title="Services" des="Marcus Moran sells many different forms of Insurance, and we are certain we can find one that suits your needs.">
            <div>
            <div className="bg-blue-900 pb-5 md:pb-10 mb-10 relative">
                <Img fluid={banner.sizes} alt="Banner"/>
                <h1 className="absolute text-2xl md:text-4xl lg:text-5xl text-blue-900 italic words-xs lg:words-md">Services</h1>
            </div>

                <div className="max-w-xs sm:max-w-sm md:max-w-xl lg:max-w-4xl mx-auto mt-10">
                    <img className="mx-auto" src={logo.fixed.src} alt="The SSIP insurance logo"/>
                </div>

                <div className="block md:flex md:mx-auto md:max-w-2xl lg:max-w-4xl xl:max-w-6xl justify-between py-10">
                    {insurances.map((insurance) => {
                        return <InsuranceType title={insurance.name} list={insurance.insurances}/>
                    })}
                </div>
            </div>
        </Layout>
    )
}

export const query = graphql`
    query{
        allContentfulServicesPage {
            nodes {
              banner {
                sizes(maxWidth: 1600, quality: 100) {
                    ...GatsbyContentfulSizes
                }
              }
              logo {
                fixed{
                  src
                }
              }
              insuranceTypes {
                name
                insurances
              }
            }
          }
    }
`

